import React from "react";
import { motion } from "framer-motion";
// import { PopupButton } from "react-calendly";
import { NavLink as Link } from "react-router-dom";

// Assets
import "./Hero.css";
import newHeroImage from "../../images/new-hero-img.jpg";
import heroBG from "../../images/img-bg.svg";
import heroBGBorder from "../../images/img-bg-border.svg";

const transition = { type: "spring", duration: 3 };


const Hero = () => {
    // Click event that scroll to the top of the page
    const handleClickScroll = () => {
      window.scrollTo(0, 0);
    }; 
  return (
    <div className="Hero">
        
        {/* Left Side */}
        <div className="l-side">
            <p className="hero-title">Busola Olamuyiwa</p>
            <h1 className="hero-head-text">Private Mental Health Consultation from a Professional</h1>
            <p className="hero-text">Guiding you towards inner strength, resilience, and growth. Find clarity, healing, and empowerment through compassionate counseling tailored to your unique journey. Take the first step towards a brighter tomorrow.</p>
            <Link to="/book-session"  onClick={handleClickScroll}><div className="btn btn-secondry">Book a Session</div></Link>
            {/* <PopupButton
              url="https://calendly.com/theunforsaken"
              rootElement={document.getElementById("root")}
              text="Book a Session"
              className="btn btn-secondry"
              styles={{whiteSpace: "nowrap", width: "180px"}}
            /> */}
        </div>

        {/* Right Side */}
        <div className="r-side">
            <motion.img 
              src={newHeroImage}
              transition={transition}
              loading="lazy"
              initial={{ bottom: "160px" }}
              whileInView={{ bottom: "35px" }}
              alt="Hero Banner" className="stack hero-image"
            />
            <motion.img 
              transition={transition}
              initial={{ top: "160px" }}
              whileInView={{ top: "70px" }}
              src={heroBGBorder} 
              alt="Hero-Border" className="stack img-border"
            />
            <motion.img 
              src={heroBG} 
              transition={transition}
              initial={{ opacity: 0 }}
              loading="lazy"
              whileInView={{ opacity: 1 }}
              alt="Hero-BG" className="stack img-bg"
            />
        </div>
    </div>
  )
}

export default Hero